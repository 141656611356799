.content-module__XBP5Dq__wrapper {
  flex-direction: column;
  gap: 40px;
  display: flex;
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}

.content-module__XBP5Dq__dialog {
  z-index: var(--wcom-z-newDataUpdate);
  filter: none;
  background: var(--wcom-c-white);
  width: 300px;
  color: var(--wcom-c-blueDark);
  text-align: center;
  align-self: center;
  padding: 20px 20px 50px;
  position: relative;
}

.content-module__XBP5Dq__warningIcon {
  background: var(--wcom-c-orangeLighter);
  width: 52.5px;
  height: 52.5px;
  color: var(--wcom-c-white);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  display: flex;
  position: absolute;
  top: -26.25px;
  left: 50%;
  transform: translateX(-50%);
}

.content-module__XBP5Dq__reload {
  margin: 20px 0 10px;
}

.content-module__XBP5Dq__message {
  margin: 30px 0 0;
  font-size: 15px;
  line-height: 23px;
}

.content-module__XBP5Dq__link {
  background: var(--wcom-c-blueMidDark);
  color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 15px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.content-module__XBP5Dq__link span {
  margin-left: 5px;
}

